import { FaSnapchat, FaInstagram, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'

export const sideNavData = [
    {
        id: 1,
        text: "سناب شات",
        icon: FaSnapchat,
        href: "https://www.snapchat.com/add/s.onelounge",
    },
    {
        id: 2,
        text: "إنستجرام",
        icon: FaInstagram,
        href: "https://instagram.com/s.onelounge",
    },
    {
        id: 3,
        text: "واتساب",
        icon: FaWhatsapp,
        href: "https://api.whatsapp.com/send/?phone=966560512525",
    },
    { id: 4, text: "اتصل بنا", icon: FaPhoneAlt, href: "tel:0560512525" },
]
export const data = [
    {
        id: 1,
        title: 'القهوة الساخنة',
        image: '../../../img/list/coffe-cup.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'سبانش لاتيه',
                description: 'مزيج من القهوة والحليب مضاف له الصوص المحلى',
                image: '../../img/food/lateh.jpg',
                price: 22,
                calories: 360,
                selected: false,
            },
            {
                id: 2,
                title: 'لاتيه',
                description: 'مزيج بين الحليب و(دبل شوت) اسبريسو',
                image: '../../img/food/lateh.jpg',
                price: 21,
                calories: 360,
                selected: false,
            },
            {
                id: 3,
                title: 'كابتشينو',
                description: 'بن أثيوبي كلاسك مضاف له الحليب المبخر',
                image: '../../img/food/lateh.jpg',
                price: 18,
                calories: 300,
                selected: false,
            },
            {
                id: 4,
                title: 'فلات وايت',
                description: 'مزيج بين الحليب و(سنقل شوت) اسبريسو',
                image: '../../img/food/lateh.jpg',
                price: 18,
                calories: 110,
                selected: false,
            },
            {
                id: 5,
                title: 'ماكياتو',
                description: 'مزيج بين الاسبريسو ورغوة الحليب المخفوق',
                image: '../../img/food/lateh.jpg',
                price: 15,
                calories: 162,
                selected: false,
            },
            {
                id: 6,
                title: 'كورتادو',
                description: 'مزيج بين الاسبريسو ورغوة الحليب المخفوق',
                image: '../../img/food/lateh.jpg',
                price: 16,
                calories: 168,
                selected: false,
            },
            {
                id: 7,
                title: 'ڤي60',
                description: 'بن اثيوبي مقطر',
                image: '../../img/food/amricano.jpg',
                price: 20,
                calories: 3,
                selected: false,
            },
            {
                id: 8,
                title: 'امريكانو',
                description: 'دبل شوت من الاسبريسو مضاف له الماء الساخن',
                image: '../../img/food/amricano.jpg',
                price: 15,
                calories: 12,
                selected: false,
            },
            {
                id: 9,
                title: 'قهوة تركية',
                description: 'من اجود أنواع البن التركي معد بالطريقة الخاصة',
                image: '../../img/food/turki.jpg',
                price: 20,
                calories: 20,
                selected: false,
            },
            {
                id: 10,
                title: 'اسبريسو سنجل',
                description: 'بن أثيوبي بنكهة كلاسيكية',
                image: '../../img/food/ispressop.jpg',
                price: 11,
                calories: 4,
                selected: false,
            },
            {
                id: 11,
                title: 'دبل اسبريسو',
                description: 'بن أثيوبي بنكهة كلاسيكية',
                image: '../../img/food/ispressop.jpg',
                price: 15,
                calories: 8,
                selected: false,
            },
            {
                id: 12,
                title: 'هوت شوكلت',
                description: 'شوكلاتة ساخنة معدة بطريقة خاصة',
                image: '../../img/food/italiano.jpg',
                price: 18,
                calories: 192,
                selected: false,
            },
            {
                id: 13,
                title: 'كوب قهوه سعودية',
                description: 'يضاف لها الهيل والخلطة الخاصة حسب الرغبة',
                image: '../../img/food/kup-8ohoh.jpg',
                price: 12,
                calories: 2,
                selected: false,
            },
            {
                id: 14,
                title: 'دلة قهوه سعودية',
                description: 'يضاف لها الهيل والخلطة الخاصة حسب الرغبة',
                image: '../../img/food/dalla.jpg',
                price: 30,
                calories: 5,
                selected: false,
            },
        ]
    },
    {
        id: 2,
        title: 'القهوة الباردة',
        image: '../../../img/list/bard.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'ايس سبانيش لاتيه',
                description: '',
                image: '../../img/food/icsbansh2.jpg',
                price: 24,
                calories: 230,
                selected: false,
            },
            {
                id: 2,
                title: 'ايس بستاشيو لاتيه',
                description: '',
                image: '../../img/food/ice-pstachio.jpg',
                price: 24,
                calories: 256,
                selected: false,
            },
            {
                id: 5,
                title: 'ايس وايت موكا',
                description: '',
                image: '../../img/food/icsbansh.jpg',
                price: 24,
                calories: 112,
                selected: false,
            },
            {
                id: 4,
                title: 'ايس موكا',
                description: '',
                image: '../../img/food/icemoca.jpg',
                price: 24,
                calories: 112,
                selected: false,
            },
            {
                id: 3,
                title: 'ايس دريب',
                description: '',
                image: '../../img/food/ice-drip.jpg',
                price: 24,
                calories: 110,
                selected: false,
            },
            {
                id: 7,
                title: 'ايس امريكانو ',
                description: '',
                image: '../../img/food/ice-amricano.jpg',
                price: 18,
                calories: 210,
                selected: false,
            },
            {
                id: 6,
                title: 'ايس سيجنيتشر ',
                description: '',
                image: '../../img/food/spanish.jpg',
                price: 24,
                calories: 209,
                selected: false,
            },
            {
                id: 7,
                title: 'ايس لاتيه ',
                description: '',
                image: '../../img/food/spanish.jpg',
                price: 23,
                calories: 195,
                selected: false,
            },
            {
                id: 8,
                title: 'ايس فرابتشينو ',
                description: '',
                image: '../../img/food/caramel.jpg',
                price: 24,
                calories: 195,
                selected: false,
            },
            
        ]
    },
    {
        id: 3,
        title: 'الشاي',
        image: '../../../img/list/tea.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كوب شاهي أحمر',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/teee.jpg',
                price: 7,
                calories: 2,
                selected: false,
            },
            {
                id: 2,
                title: 'كوب شاهي أخضر',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/teee.jpg',
                price: 7,
                calories: 2,
                selected: false,
            },
            {
                id: 3,
                title: 'كوب شاهي مغربي',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/teee.jpg',
                price: 8,
                calories: 2,
                selected: false,
            },
            {
                id: 4,
                title: 'كوب نعناع ساده',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/teee.jpg',
                price: 8,
                calories: 2,
                selected: false,
            },
            {
                id: 4,
                title: 'براد شاهي أحمر',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/te.jpg',
                price: 25,
                calories: 2,
                selected: false,
            },
            {
                id: 5,
                title: 'براد شاهي أخضر',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/magrabe.jpg',
                price: 25,
                calories: 2,
                selected: false,
            },
            {
                id: 5,
                title: 'براد شاهي مغربي',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/magrabe.jpg',
                price: 25,
                calories: 2,
                selected: false,
            },
            {
                id: 5,
                title: 'براد نعناع ساده',
                description: 'يضاف السكر والنعناع والحبق حسب الرغبة',
                image: '../../img/food/magrabe.jpg',
                price: 25,
                calories: 2,
                selected: false,
            },
            
        ]
    },
    {
        id: 4,
        title: 'المشروبات باردة',
        image: '../../../img/list/moheto5a9.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'موهيتو بلاك بيري',
                description: '',
                image: '../../img/food/mix-berry.jpg',
                price: 22,
                calories: 213,
                selected: false,
            },
            {
                id: 2,
                title: 'موهيتو مكس بيري',
                description: '',
                image: '../../img/food/mix-berry.jpg',
                price: 22,
                calories: 213,
                selected: false,
            },
            {
                id: 3,
                title: 'موهيتو بلو بيري',
                description: '',
                image: '../../img/food/blo-oshn.jpg',
                price: 19,
                calories: 133,
                selected: false,
            },
            {
                id: 4,
                title: 'موهيتو بلو اوشن',
                description: '',
                image: '../../img/food/blo-oshn.jpg',
                price: 19,
                calories: 140,
                selected: false,
            },
            {
                id: 5,
                title: 'موهيتو ماجيك',
                description: '',
                image: '../../img/food/magic.jpg',
                price: 19,
                calories: 173,
                selected: false,
            },
            {
                id: 6,
                title: 'موهيتو رمان',
                description: '',
                image: '../../img/food/mohitofraolh.jpg',
                price: 19,
                calories: 186,
                selected: false,
            },
            {
                id: 7,
                title: 'موهيتو بطيخ',
                description: '',
                image: '../../img/food/mohitofraolh.jpg',
                price: 19,
                calories: 177,
                selected: false,
            },
            {
                id: 8,
                title: 'موهيتو فراوله',
                description: '',
                image: '../../img/food/mohitofraolh.jpg',
                price: 19,
                calories: 110,
                selected: false,
            },
            {
                id: 9,
                title: 'موهيتو باشن فروت',
                description: '',
                image: '../../img/food/ice-tea-limon.jpg',
                price: 19,
                calories: 188,
                selected: false,
            },
            {
                id: 13,
                title: 'ايس تي توت',
                description: 'خلاصة الشاي المثلج مضاف لها نكهة التوت',
                image: '../../img/food/ice-tea-tot.jpg',
                price: 22,
                calories: 104,
                selected: false,
            },
            {
                id: 12,
                title: 'ايس تي ليمون',
                description: 'خلاصة الشاي المثلج مضاف لها نكهة الليمون',
                image: '../../img/food/ice-tea-limon.jpg',
                price: 22,
                calories: 89,
                selected: false,
            },
            {
                id: 11,
                title: 'ايس تي خوخ',
                description: 'خلاصة الشاي المثلج مضاف لها نكهة الخوخ',
                image: '../../img/food/mohito-bashn.jpg',
                price: 22,
                calories: 133,
                selected: false,
            },
            {
                id: 13,
                title: 'ايس تي باشن فروت',
                description: 'خلاصة الشاي المثلج مضاف لها نكهة الفواكة المشكلة',
                image: '../../img/food/mohito-bashn.jpg',
                price: 22,
                calories: 104,
                selected: false,
            },
            {
                id: 10,
                title: 'فرابتشينو سيجنيتشر',
                description: 'آيس كريم بنكهة الفانيلا مضاف لها حليب وصوص الكراميل مع القهوة',
                image: '../../img/food/frabtshino.jpg',
                price: 29,
                calories: 152,
                selected: false,
            },
            {
                id: 14,
                title: 'كودرد ',
                description: 'مشروب غازي',
                image: '../../img/food/codred.png',
                price: 12,
                calories: 166,
                selected: false,
            },
            {
                id: 15,
                title: 'بيره شعير ',
                description: 'مشروب غازي',
                image: '../../img/food/holiston.png',
                price: 10,
                calories: 37,
                selected: false,
            },
            {
                id: 16,
                title: 'سبارك ',
                description: 'مشروب غازي',
                image: '../../img/food/spark.jpg',
                price: 8,
                calories: 168,
                selected: false,
            },
            {
                id: 17,
                title: 'ببسي ',
                description: 'مشروب غازي',
                image: '../../img/food/pebsi.jpg',
                price: 8,
                calories: 114,
                selected: false,
            },
            {
                id: 18,
                title: 'سفن اب ',
                description: 'مشروب غازي',
                image: '../../img/food/sfn.jpeg',
                price: 8,
                calories: 140,
                selected: false,
            },
            {
                id: 19,
                title: 'صودا ',
                description: 'مياة غازية',
                image: '../../img/food/soda.jpg',
                price: 10,
                calories: 1,
                selected: false,
            },
            {
                id: 20,
                title: 'مياة معدنية ',
                description: 'مياة نوفا مياة محلية سعودية',
                image: '../../img/food/nofa.png',
                price: 2,
                calories: 0,
                selected: false,
            },
        ]
    },
    {
        id: 5,
        title: 'العصيرات الطازجه',
        image: '../../../img/list/drink.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'عصير مانجو',
                description: 'مشروب الفاكهة الطازجة',
                image: '../../img/food/mango.jpg',
                price: 19,
                calories: 90,
                selected: false,
            },
            {
                id: 2,
                title: 'عصير برتقال',
                description: 'مشروب الفاكهة الطازجة',
                image: '../../img/food/bortogal.jpg',
                price: 19,
                calories: 44,
                selected: false,
            },
            {
                id: 3,
                title: 'عصير جوافه',
                description: 'مشروب الفاكهة الطازجة',
                image: '../../img/food/joafa.jpg',
                price: 19,
                calories: 57,
                selected: false,
            },
            {
                id: 4,
                title: 'عصير فراوله',
                description: 'مشروب الفاكهة الطازجة',
                image: '../../img/food/fraola.jpg',
                price: 19,
                calories: 90,
                selected: false,
            },
            {
                id: 5,
                title: 'عصير ليمون نعناع',
                description: 'مشروب الفاكهة الطازجة',
                image: '../../img/food/limon.JPG',
                price: 19,
                calories: 50,
                selected: false,
            },
           
        ]
    },
    {
        id: 6,
        title: 'الحلا',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'بستاشيو',
                description: '',
                image: '../../img/food/8e.jpg',
                price: 25,
                calories: 480,
                selected: false,
            },
            {
                id: 2,
                title: 'فيريرو روشيه',
                description: '',
                image: '../../img/food/9.jpg',
                price: 25,
                calories: 490,
                selected: false,
            },
            {
                id: 3,
                title: 'تشيز كيك اوريو',
                description: '',
                image: '../../img/food/cheez-orio.jpg',
                price: 25,
                calories: 430,
                selected: false,
            },
            {
                id: 4,
                title: 'تشيز كيك توفي',
                description: '',
                image: '../../img/food/3.jpg',
                price: 25,
                calories: 480,
                selected: false,
            },
            {
                id: 5,
                title: 'تشيز كيك مانجو',
                description: '',
                image: '../../img/food/5.jpg',
                price: 25,
                calories: 350,
                selected: false,
            },
            {
                id: 6,
                title: 'تشيز كيك بلو بيري',
                description: '',
                image: '../../img/food/7.jpg',
                price: 25,
                calories: 350,
                selected: false,
            },
            {
                id: 7,
                title: 'تشيز كيك لوتس',
                description: '',
                image: '../../img/food/2.jpg',
                price: 25,
                calories: 390,
                selected: false,
            },
            {
                id: 8,
                title: 'ايس كريم تشيز لوتس',
                description: '',
                image: '../../img/food/lots.jpg',
                price: 30,
                calories: 480,
                selected: false,
            },
            {
                id: 9,
                title: 'رمان بستاشيو',
                description: '',
                image: '../../img/food/roman.jpg',
                price: 25,
                calories: 340,
                selected: false,
            },
            {
                id: 10,
                title: 'رد فيلفت',
                description: '',
                image: '../../img/food/vilvit.jpg',
                price: 25,
                calories: 390,
                selected: false,
            },
            {
                id: 11,
                title: 'مولتن درب',
                description: '',
                image: '../../img/food/moltndrip.jpg',
                price: 25,
                calories: 430,
                selected: false,
            },
            {
                id: 12,
                title: 'ميني بان كيك',
                description: '',
                image: '../../img/food/pankik.jpg',
                price: 25,
                calories: 430,
                selected: false,
            },
            {
                id: 13,
                title: 'فرنش توست',
                description: '',
                image: '../../img/food/frnchtost.jpg',
                price: 25,
                calories: 430,
                selected: false,
            },
        ]
    },
    {
        id: 7,
        title: 'الشيشة',
        image: '../../../img/list/shisha.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'تفاحتين فاخر',
                description: '',
                image: '../../img/food/tofa7tin.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 2,
                title: 'تفاحتين نخلة',
                description: '',
                image: '../../img/food/tofa7tin.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 3,
                title: 'تفاحتين مكس',
                description: '',
                image: '../../img/food/tofa7tin.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 4,
                title: 'تفاحتين بيروست',
                description: '',
                image: '../../img/food/tofa7tin.jpg',
                price: 50,
                calories: 0,
                selected: false,
            },
            {
                id: 5,
                title: 'عنب توت فاخر',
                description: '',
                image: '../../img/food/3nb-tot.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 6,
                title: 'عنب توت مزايا',
                description: '',
                image: '../../img/food/3nb-tot.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 7,
                title: 'عنب نعناع فاخر',
                description: '',
                image: '../../img/food/3nb-n3n3.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 8,
                title: 'عنب نعناع مزايا',
                description: '',
                image: '../../img/food/3nb-n3n3.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 9,
                title: 'عنب ساده',
                description: '',
                image: '../../img/food/3nb.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 10,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/food/limon-n3n3.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 11,
                title: 'علكة نعناع',
                description: '',
                image: '../../img/food/3lkh-n3n3.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 12,
                title: 'علكة مستكه',
                description: '',
                image: '../../img/food/mstkh.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 13,
                title: 'علكه قرفه',
                description: '',
                image: '../../img/food/8rfh.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 14,
                title: 'بلو بيري',
                description: '',
                image: '../../img/food/blooberry.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 15,
                title: 'بطيخ نعناع فاخر',
                description: '',
                image: '../../img/food/b6y5.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 16,
                title: 'بطيخ نعناع مزايا',
                description: '',
                image: '../../img/food/b6y5.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
            {
                id: 17,
                title: 'بطيخ نعناع فاخر',
                description: '',
                image: '../../img/food/b6y5.jpg',
                price: 45,
                calories: 0,
                selected: false,
            },
        ]
    },
]

export default data